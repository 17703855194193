.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ma-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.mt-minus-1 {
  margin-top: -1rem !important;
}

.mt-half {
  margin-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.mb-half {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.ml-half {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.mr-half {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.m-5 {
  margin: 5rem !important;
}

.p-5 {
  padding: 5rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.pl-5 {
  padding-left: 5rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.pr-5 {
  padding-right: 5rem !important;
}
