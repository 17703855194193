.ant-tooltip-inner {
  color: black !important;
  background-color: white !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  border: 1px solid #aeb4d2 !important;
}

.ant-tooltip-arrow-content {
  background-color: transparent !important;
}

.ant-input {
  border-radius: 8px !important;
  border-color: #aeb4d2 !important;
  padding: 6px 11px !important;
  height: 41px;
}

.ant-form-item-label > label {
  margin-top: 5px !important;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: -1px;
  transition: transform 0.28s ease;
  border-radius: 4px;
  border: 1px solid #454a67;
  background-color: #fff;
}

input[type="checkbox"]:after {
  content: "";
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 15px;
  left: 3px;
}

input[type="checkbox"]:checked::before {
  background-color: #454a67;
}

input[type="checkbox"]:checked::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.ant-input-group .ant-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  background-color: #fff !important;
  border-color: #aeb4d2 !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 0 !important;
  height: 36px !important;
  border-left: none !important;
  color: #414661 !important;
  padding: 0 5px !important;
  width: 50px;
  height: 41px !important;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input-search .ant-input:active {
  border-right: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper {
  border-color: #aeb4d2 !important;
  border-radius: 8px !important;
  height: 43px;
  padding: 4px 10px 4px 0 !important;
}

.ant-input-affix-wrapper > input.ant-input {
  position: relative;
  top: -4px;
  padding: 0;
}

.ant-form-item-label > label::before {
  content: "" !important;
}

.ant-form-item-label > label::after {
  content: "*" !important;
}

.ant-select-selector {
  border-radius: 8px !important;
  border-color: #aeb4d2 !important;
  height: 43px !important;
  padding: 4px 11px !important;
}

.ant-select-arrow {
  color: #414661 !important;
}

.ant-btn {
  background-color: #89aaff !important;
  border-radius: 8px !important;
  border-color: #89aaff !important;
  padding: 6px 15px !important;
  height: 40px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.ant-btn.ant-btn-background-ghost {
  background-color: transparent !important;
  border-color: #414661 !important;
  color: #5f668c !important;
  font-weight: 600 !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border-color: #d2d6e9 !important;
  background: #d2d6e9 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.ant-btn-dangerous {
  width: 170px !important;
  background-color: #f16969 !important;
  border-color: #f16969 !important;
  font-weight: 600 !important;
}

.ant-notification-notice {
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(132, 140, 183, 0.3);
  border: 1px solid #c5cbe7;
  border-radius: 24px !important;
}

.ant-notification-notice-message {
  font-size: 1.5rem;
}

.ant-notification-notice-description {
  font-size: 1 !important;
  line-height: 1 !important;
  margin-left: 30px !important;
}

.ant-notification-notice-close {
  color: #fff !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  color: #fff !important;
  margin-left: 30px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-weight: 400 !important;
  color: #fff !important;
  opacity: 0.7;
  font-size: 1rem;
  line-height: 1.2;
  margin: 1rem 0 0.5rem 2.2rem;
  white-space: pre-wrap;
}

.ant-notification-notice-icon {
  height: calc(100% - 34px);
}

.ant-notification-notice-success {
  background-color: #89aaff !important;
}

.ant-notification-notice-error {
  background-color: #f36161 !important;
}

.ant-notification-notice-warning {
  background-color: #f9b200 !important;
}

.ant-notification-notice-info {
  background-color: #7a58bf !important;
}

.ant-timeline-item-tail {
  border-color: #414661 !important;
}

.ant-timeline-item-head {
  background-color: #414661 !important;
}

.ant-picker-range {
  border-radius: 8px !important;
  border-color: #aeb4d2 !important;
  height: 41px !important;
  padding: 4px 11px !important;
  width: 100%;
}

.ant-picker-suffix > * {
  color: #414661 !important;
}

.ant-picker {
  border-radius: 8px !important;
  border-color: #aeb4d2 !important;
  height: 43px !important;
  padding: 4px 11px !important;
  width: 100%;
}

.ant-checkbox-inner,
.ant-checkbox-checked,
.ant-checkbox-checked::after {
  border-color: #414661 !important;
  border-radius: 4px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #414661 !important;
  border-radius: 4px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #414661 !important;
  border-radius: 4px !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #414661 !important;
}

.ant-radio-inner::after {
  background-color: #414661 !important;
  border-color: #414661 !important;
}

.ant-radio-checked::after {
  border-color: #414661 !important;
}

.ant-picker-ok > button {
  display: none;
}